import React from "react"
import { graphql } from "gatsby";
import { Team } from "../components/team"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `TEAM`, path: `team` },
]

export default function Home({data}) {
  const { ogpImage } = data;

  return (
    <Team
      breadcrumbsData={breadcrumbsData}
      ogpImage={ogpImage}
    />
  )
}

export const query = graphql`
  query {
    ogpImage: file(relativePath: { eq: "images/team_ogp.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
