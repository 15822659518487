import React from "react"
import styled from "styled-components"

/**
 * Element & Styles
 */
const Node = styled.div`
  display: flex;
  width: 100%;
`
const ImgWrapper = styled.div`
`

const Img = styled.img`
  width: 80px;
  height: 80px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
`

const Title = styled.h3`
  margin: 0 0 8px;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  color: #222222;
`

const Description = styled.p`
  margin: 0;
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  color: #222222;
`

/**
 * Props
 */
type Props = {
  img: any;
  title: string;
  description: string;
}

/**
 * Component
 */
export const TeamList: React.VFC<Props> = (props) => {
  return (
    <Node>
      <ImgWrapper>
        <Img src={props.img} alt={props.title} />
      </ImgWrapper>
      <TextWrapper>
        <Title>{props.title}</Title>
        <Description dangerouslySetInnerHTML={{__html: props.description}} />
      </TextWrapper>
    </Node>
  )
}
