import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head";
import { TeamList } from "./TeamList"

/**
 * Material
 */
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { RESPONSIVE_STYLES, WRAPPER_STYLES, TABLET_STYLES } from "../../AppConst";
import { SectionTitle } from '../common/SectionTitle'

import teamAll from '../../images/team_all.svg'
import teamSales from '../../images/team_sales.svg'
import teamRisk from '../../images/team_risk.svg'
import teamRecruit from '../../images/team_recruit.svg'
import teamProduct from '../../images/team_product.svg'
import teamMedia from '../../images/team_media.svg'
import teamIT from '../../images/team_it.svg'
import teamCreative from '../../images/team_creative.svg'
import teamDesign from '../../images/team_design.svg'
import teamCorp from '../../images/team_corp.svg'
import team01EC from '../../images/team_01ec.svg'

import { ogpUrl } from "../../utlis/ImageUtil"

/**
 * Element & Styles
 */
const Node = styled.div`
  text-align: center;
  ${TABLET_STYLES}
`

const PCWrapper = styled.div`
  margin: 96px 0;
  ${WRAPPER_STYLES}
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  }
`

const SPWrapper = styled.div`
  display: none;
  margin: 48px 0 80px;
  padding: 0 20px;
  box-sizing: border-box;
  ${WRAPPER_STYLES}
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const Img = styled.img`
  width: 100%;
  max-width: 1069px;
`

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"];
  ogpImage: any;
}

/**
 * Component
 */
export const Team: React.VFC<Props> = (props) => {

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="組織 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の組織ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.ogpImage.childImageSharp.fluid)}
      />
      <Node>
        <SectionTitle title='team' subTitle='組織' />
        <PCWrapper>
          <Img src={teamAll} alt="TEAM 組織" />
        </PCWrapper>
        <SPWrapper>
          <TeamList
            img={teamRisk}
            title={`リスクマネジメントチーム`}
            description={`情報セキュリティ推進 ISMS事務局`}
          />
          <TeamList
            img={teamSales}
            title={`セールスチーム`}
            description={`プロダクトセールス、ソリューションセールス`}
          />
          <TeamList
            img={teamIT}
            title={`ITソリューションチーム`}
            description={`システム開発支援サービス`}
          />
          <TeamList
            img={teamCreative}
            title={`クリエイティブソリューションチーム`}
            description={`Webシステム開発<br />オーダーソフトウェア開発`}
          />
          <TeamList
            img={teamDesign}
            title={`デザインチーム`}
            description={`プロダクトデザイン（UI/UX）<br />Web・グラフィックデザイン`}
          />
          <TeamList
            img={teamProduct}
            title={`プロダクトイノベーションチーム`}
            description={`SaaSプロダクト開発、オウンドメディア開発`}
          />
          <TeamList
            img={teamMedia}
            title={`メディアマーケティングチーム`}
            description={`オウンドメディア・ソーシャルメディアの運営、Webマーケティング`}
          />
          <TeamList
            img={team01EC}
            title={`0→1 ENGINEER CAMPチーム`}
            description={`エンジニア育成「0→1 ENGINEER CAMP」の開発・運営`}
          />
          <TeamList
            img={teamRecruit}
            title={`人事採用チーム`}
            description={`人材採用、人材育成`}
          />
          <TeamList
            img={teamCorp}
            title={`コーポレートマネジメントチーム`}
            description={`バックオフィス業務`}
          />
        </SPWrapper>
      </Node>
    </Layout>
  )
}